.leads {
    position: relative;
    overflow: hidden;
    padding: 40px 50px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    > img {
        max-width: 100%;
    }
}

.loader {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    > img {
        max-width: 60px;
    }
}

.grid {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.gridRow {
    position: relative;
    &:first-child {
        .gridItem {
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 10000px;
                height: 1px;
                background-color: #d9d9d9;
            }
        }
    }
    &:last-child {
        .gridHead {
            text-align: right;
        }
        .gridItem {
            justify-content: flex-end;
        }
    }
}

.gridHead {
    position: relative;
    font-weight: 500;
    font-size: 15px;
    height: 35px;
}

.gridItem {
    height: 32px;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    &:last-child {
        text-align: right;
    }

    > button {
        display: flex;
        background: #f9fafd;
        border-radius: 4px;
        padding: 6px;
        cursor: pointer;
        transition: opacity 0.2s ease-in-out;
        &:hover {
            opacity: 0.8;
        }
    }
}

.header {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    justify-content: flex-end;
}

.export {
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    color: #2990fb;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: opacity 0.2s ease-in-out;
    > img {
        margin-left: 12px;
    }
    &:hover {
        opacity: 0.8;
    }
}

.noContent {
    padding: 100px 0;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: #626262;
    text-align: center;
}
