.then-now-block {
    width: 100%;
    overflow: hidden;
    &__img {
        background-size: cover;
        background-position: center;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    &__img-container {
        position: relative;
        width: inherit;
        height: inherit;
    }
    &__img-inner-container {
        position: absolute;
        width: inherit;
        height: inherit;
        top: 0;
    }
    &__delimiter {
        position: absolute;
        top: 0;
        width: 4px;
        height: 100%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        transition: opacity 0.25s;
        background-color: #ffffff;
        > img {
            position: absolute;
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }
}
.timeline-block {
    padding: 33px 12px 30px 70px;
    text-align: left;

    ~ .timeline-block, // All but first
    + :not(.timeline-block) {
        // If next one is not a timeline block - pull it up!
        margin-top: 0;
    }
    + .timeline-block {
        // Because of using padding instead of margins we have to imitate the margin-collapsing
        padding-top: 3px;

        &::after {
            top: 25px;
        }
    }
    &::before {
        content: ' ';
        position: absolute;
        width: 1px;
        background-color: #000;
        top: 0;
        bottom: 0;
        left: 40px;
    }
    &::after {
        content: ' ';
        position: absolute;
        width: 11px;
        height: 11px;
        background-color: #000;
        top: 55px;
        left: 35px;
        border-radius: 5.5px;
    }

    &__mark {
        &-title-input,
        &-subtitle-input {
            font-family: 'Georgia', sans-serif;
            font-style: normal;
            font-weight: 400;
            margin-bottom: 20px;
            padding: 0;
            display: block;
            width: 100%;
            border-style: dashed;
            border-width: 1px;
            outline: none;

            &:focus {
                border-color: #4891f3;
            }
        }
        &-title-input {
            font-size: 40px;
            line-height: 45px;
        }
        &-subtitle-input {
            font-size: 32px;
            line-height: 28px;
        }
    }

    &__image-with-description {
        width: 35%;
        min-width: 35%;
    }
    &__image-container {
        margin-bottom: 15px;
    }
    &__image {
        width: 100%;
    }
    &__image-description {
        color: #626262;
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
    }
    &__image-container,
    &__image-description {
        float: left;
        margin-right: 20px;
    }
    &__text {
        flex-grow: 1;
    }
}
.memory-cards-block {
    background-color: #fff;
    position: relative;
    padding: 0;
    overflow: hidden;

    .memory-cards-block__preview {
        opacity: 0.4;
    }
}

.block_page {
    .block {
        position: relative;
        text-align: center;
        min-height: 10px;
        &.__1 {
            padding: 12px 16px;
            text-align: left;
        }
        &.__2,
        &.__3,
        &.__4 {
            background-color: transparent;
            perspective: 1000px;
            .flip_inner {
                position: relative;
                width: 100%;
                height: 100%;
                text-align: center;
                transition: transform 0.8s;
                transform-style: preserve-3d;

                &.__flipped {
                    transform: rotateY(180deg);
                }
            }
            .flip_side {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
                background-size: cover;
                background-position: center;
                &.__back {
                    transform: rotateY(180deg);
                }
            }
            .flip_switch {
                position: absolute;
                top: 10px;
                left: 10px;
                padding: 2px;
                border-radius: 4px;
                background-color: #979797;
                color: #fff;
                font-size: 12px;
                cursor: pointer;
                -webkit-user-select: none;
                user-select: none;
                & p {
                    display: inline-block;
                    padding: 3px 7px;
                    border-radius: 2px;
                    transition: all 0.1s linear;
                    &.active {
                        color: #3c3c3c;
                        background-color: #fff;
                    }
                }
                &:hover {
                    background-color: #787878;
                }
            }
            .text-area-cnt {
                position: absolute;
                color: #fff;
                background: transparent;
                overflow: visible;
                width: 100%;
                height: 100%;
                /* pointer-events: none; */
                .text-area {
                    transform: translateY(-50%);
                    box-sizing: border-box;
                    position: absolute;
                    color: #333;
                    padding: 3%;
                    background: transparent;
                    border: 1px dashed hsla(0, 0%, 100%, 0.6);
                    resize: none !important;
                    width: 90%;
                    top: 50%;
                    left: 5%;
                }
            }
        }
        &.__5 {
            aspect-ratio: 16 / 9;
            background-color: #b3d7fc;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 20px;
            box-sizing: border-box;

            & p {
                background: #ffffff;
                padding: 12px 20px;
                border-radius: 8px;
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #2990fb;
            }
        }
        &.__6 {
            padding: 12px 15px;
            .rbtn {
                display: inline-block;
                box-sizing: border-box;
                border-radius: 4px;
                padding: 8px 15px;
                background-color: #f06666;
                min-width: 100px;
            }
        }
        &.__15 {
            background-color: #fff;
            position: relative;
            padding: 0;
            overflow: hidden;

            > div.approx-preview {
                opacity: 0.4;
                position: relative;

                > img.image {
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                    height: 600px;
                }

                > div.box {
                    margin: -122px 20px 20px;
                    padding: 28px 60px 16px;
                    background-color: #fff;
                    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;
                    position: inherit;

                    &.no-image {
                        margin-top: 20px;
                    }

                    > div.head {
                        font-family: 'Roboto', sans-serif;
                        font-weight: 500;
                        font-size: 36px;
                        line-height: 42px;
                        text-align: center;
                        color: #000000;
                        margin-bottom: 16px;
                        white-space: pre-line;
                    }

                    > div.description {
                        font-family: 'Roboto', sans-serif;
                        font-size: 20px;
                        line-height: 28px;
                        text-align: center;
                        color: #000000;
                        margin-bottom: 20px;
                        white-space: pre-line;
                    }

                    > div.btn-wrap {
                        display: flex;
                        justify-content: center;

                        > div.btn {
                            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
                            border-radius: 4px;
                            padding: 0 44px;
                            display: flex;
                            align-items: center;
                            height: 52px;
                            font-family: 'Roboto', sans-serif;
                            font-size: 20px;
                            color: #ffffff;
                        }
                    }

                    > div.image-disclaimer {
                        font-family: 'Roboto', sans-serif;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        color: #b7b7b7;
                        margin-top: 24px;
                        white-space: pre-line;
                    }
                }
            }

            > div.no-cover {
                height: 640px;
                background-color: #d8d8d8;
                display: flex;
                flex-direction: column;
                justify-content: center;

                > div.icon {
                    height: 180px;
                    background-image: url('../i/trivia-editor.svg');
                    background-position: 50% 0;
                    background-repeat: no-repeat;
                }

                > div.text {
                    font-family: 'Roboto', sans-serif;
                    font-size: 16px;
                    line-height: 24px;
                    width: 400px;
                    margin: 0 auto;
                    text-align: center;
                    color: #3c3c3c;
                }
            }
        }
        &.__2,
        &.__21 {
            width: 100%;
            text-align: center;
            overflow: hidden;
            box-sizing: border-box;
            &.__empty {
                background-color: #eee;
            }
            .image-wrapper {
                position: relative;
                display: inline-block;
            }
            img {
                width: 100%;
            }
            .img__author {
                box-sizing: border-box;
                overflow: hidden;
                padding: 4px 0 8px;
                height: 42px;
                align-self: stretch;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: #787878;
                a {
                    &:hover {
                        color: #3c3c3c;
                        text-decoration: underline;
                    }
                }
            }
            img.__blur {
                filter: blur(15px);
            }
            .cursor_wr {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                width: 82px;
                height: 97px;
                line-height: normal;
                animation: clickhint 4s infinite;
            }
        }
        &.__23 {
            padding: 10px 8px;
        }
        &.__24 {
            width: 100%;
            height: 400px;
            background-color: #b3d7fc;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            box-sizing: border-box;
            padding: 20px;
            & .ico {
                padding: 0 4px 0 0;
            }

            & p {
                background: #ffffff;
                padding: 12px 20px;
                border-radius: 8px;
                font-family: 'Roboto', sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #2990fb;
            }

            .ico {
                padding: 0;
            }
        }
    }
}
