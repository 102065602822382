.backButton {
  width: 20px;
  height: 20px;
  margin: 0 4px 0 -6px;
  flex-shrink: 0;
  background-image: url('./i/back.svg');
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: opacity .2s ease-in-out;
  &:hover {
    opacity: .8;
  }
}