.select-internal-page-modal {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 450px;
    width: 640px;
    background-color: #ffffff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    padding: 50px 46px 12px;

    .simplebar-track.simplebar-vertical {
        right: -29px;
    }

    &__title {
        font-family: 'Ubuntu', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 28px;
        text-align: center;
        letter-spacing: -1px;
        color: #000000;
        margin-bottom: 35px;
    }

    &__page-list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 12px;
    }

    &__template {
        box-sizing: border-box;
        width: 100px;
        height: 128px;
        margin-bottom: 20px;
        background-color: #f2f3f7;
        border: 2px solid #f2f3f7;
        border-radius: 4px;
    }

    &__page {
        box-sizing: border-box;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100px;
        height: 128px;
        border: 2px solid #d8d8d8;
        border-radius: 4px;
        background-color: #ffffff;

        &:hover {
            border-color: #2990fb;
            .select-internal-page-modal__page-label {
                color: #2990fb;
            }
            .select-internal-page-modal__page-img {
                margin: 14px;
                background-image: url('../../i/big_link.svg');
            }
        }

        &-label {
            box-sizing: border-box;
            margin: 12px 7px 0 14px;
            min-height: 32px;
            max-height: 32px;
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #787878;
            overflow: hidden;
            text-align: left;
        }
        &-img {
            box-sizing: border-box;
            height: 84px;
            margin: 14px 14px 28px;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;

            &.is-has-blocks {
                background-image: url('../../i/page_content_icon.svg');
            }
        }
    }
}
