.listItem {
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    min-height: 20px;
    color: #737373;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
        color: #404040;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.isActiveListItem {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.1);
    .icon > svg {
        color: #404040;
    }
}

.icon {
    flex-shrink: 0;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
        max-width: 16px;
        max-height: 16px;
    }
}
