@import "../../../../../../styles/mixins";

.list {
  margin: 0 24px 80px;
}

.groupLabel {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #3c3c3c;
  margin-bottom: 16px;
}

.groupList {
  position: relative;
}

.groupItem {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}