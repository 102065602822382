.textEditorContent {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0;
    color: #000;
    text-align: left;

    &__BASIC {
        .tiptap {
            padding: 0;
        }
    }
    &__SEPARATE {
        .tiptap {
            padding: 12px 16px;
        }
    }
    &__BUTTON {
        .tiptap {
            padding: 0;
        }
    }

    span {
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0;
    }

    .tiptap {
        outline: none;
    }

    strong {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
    s {
        text-decoration: line-through;
    }
    u {
        text-decoration: underline;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }

    ul,
    ol {
        margin: 2rem 0;
        padding: 0 2rem;
        > li {
            margin: 0.25rem 0;
            > ul,
            ol {
                margin: 0.25rem 0;
                padding: 0 1rem;
            }
        }
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    img {
        max-width: 100%;
    }

    blockquote {
        border-left: 4px solid #d4d4d4;
        padding: 8px 16px;
        margin: 10px 0;
    }
}
