@import "../../../../../../../../styles/mixins";

.leadModal {
  display: flex;
  flex-direction: column;
  width: 640px;
  height: 910px;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: #3c3c3c;
  text-align: left;
}

.header {
  padding: 28px 40px 16px;
  border-bottom: 1px solid #d8d8d8;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.body {
  display: flex;
  flex-direction: column;
  margin: 20px 8px 40px 40px;
  padding-right: 32px;
  overflow-y: auto;
  @include scroll-bar();
}

.time {
  margin-bottom: 20px;
  color: #979797;
}

.boxes {
  position: relative;
}

.box {
  margin-bottom: 28px;
  &:last-child {
    margin-bottom: 0;
  }
}

.boxTitle {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 12px;
}

.boxFields {
  display: flex;
  flex-direction: column;
}

.boxField {
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  > strong {
    font-weight: bold;
  }
}

.boxFieldTitle {
  font-weight: bold;
}