.control-box {
  &.flexed{
    display: flex;
    justify-content: space-between;
    align-items: center;
    > p.label {
      margin-bottom: 0;
    }
    > div {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      color: #2990fb;
      transition: opacity 0.2s ease-in-out;
      padding-left: 10px;
      &:hover {
        opacity: 0.8;
      }
      > img {
        margin-right: 4px;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  > p.name {
    font-size: 14px;
    color: #3c3c3c;
  }
  > p.label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #3c3c3c;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 16px;

    > span.link {
      display: inline-block;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      color: #2990fb;
      margin-left: auto;
      cursor: pointer;
      vertical-align: text-top;
    }
  }
  > p.description {
    margin-top: 8px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #787878;
  }
  > span.link {
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #2990fb;
    margin-bottom: 8px;
    cursor: pointer;
    vertical-align: text-top;
  }
  > div.content {
    &.input {
      input {
        appearance: none;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #3c3c3c;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        height: 32px;
        padding: 0 8px;
        outline: none;
        &.is-error {
          border-color: #ff5656;
        }
        &:focus {
          border-color: #69b1fc !important;
          box-shadow: inset 0 0 0 1px #69b1fc;
        }
      }
    }
    &.textarea {
      textarea {
        appearance: none;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #3c3c3c;
        border: 1px solid #d8d8d8;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        outline: none;
        resize: none;
        padding: 5px 12px 6px;
        &:focus {
          border-color: #69b1fc !important;
          box-shadow: inset 0 0 0 1px #69b1fc;
        }
      }
    }
    &.checkbox {
      display: flex;
      align-items: flex-start;
      > div.checkbox-block {
        width: 14px;
        height: 14px;
        border-radius: 2px;
        cursor: pointer;
        margin-right: 8px;
        flex-shrink: 0;
        background-color: #d7d7d7;
        background-position: 50% 3px;
        background-repeat: no-repeat;
        &.is-checked {
          background-color: #2990fb;
          background-image: url('./i/checkmark.svg');
        }
      }
      > p {
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        line-height: 16px;
        color: #3c3c3c;
        display: flex;
        align-items: center;
      }
    }
    &.radio {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      > div.radio-option {
        cursor: pointer;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #3c3c3c;
        display: flex;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 8px;
        &:before {
          content: '';
          width: 14px;
          height: 14px;
          margin-right: 8px;
          background-image: url('./i/radio-button.svg');
          flex-shrink: 0;
        }
        &.is-checked {
          &:before {
            background-image: url('./i/radio-button-selected.svg');
          }
        }
      }
    }
  }
}