.classicToolbar {
    background-color: #fff;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 8px;
    padding: 4px 3px;
    height: 42px;
    box-sizing: border-box;
    margin: 0 5px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    max-width: calc(100% - 10px);
    box-sizing: border-box;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.popover {
    z-index: 10000;
    max-width: 100%;
}
