@import "../../../../../../styles/mixins";

.multiplayerBackground {
  position: fixed;
  z-index: 10;
  top: 60px;
  left: 160px;
  right: 300px;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include max_rwd(1159) {
    right: 240px;
  }
  @include max_rwd(1023) {
    top: 80px;
  }
  @include max_rwd(768) {
    left: 0;
    right: 0;
  }
}