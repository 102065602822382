.listGroup {
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
}

.title {
    text-transform: uppercase;
    font-size: 10px;
    color: rgb(115, 115, 115);
    margin-bottom: 8px;
}

.list {
    position: relative;
}
