.lead-form__form {
    display: flex;
    height: 100%;

    &__workplace-wrapper {
        width: 100%;
        box-sizing: border-box;
        background-color: #f9fafd;
        overflow: hidden;
        height: 100%;
    }
    &__workplace {
        padding: 36px 40px 20px;
        &--box {
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &-title {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            font-family: 'Ubuntu', sans-serif;
            font-weight: 500;
            font-size: 24px;
            color: #3c3c3c;
        }
        &-content {
            > div.fields-container {
                padding-bottom: 10px;

                > ul.fields {
                    display: flex;
                    flex-wrap: wrap;
                    > li {
                        width: 33.333%;
                        margin-bottom: 24px;
                        padding-right: 20px;
                        box-sizing: border-box;
                        > div {
                            padding: 0;
                            margin: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
                > .custom-field {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    & > * {
                        margin-right: 15px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    & .delete-field-button {
                        width: 24px;
                        height: 30px;
                        cursor: pointer;
                        background-image: url(../../../../i/delete2.svg);
                        background-repeat: no-repeat;
                        background-position-y: 100%;
                    }
                }
            }
        }
        &-content-authorization {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 20px;
            > p {
                width: 260px;
                margin-left: 20px;
                flex-shrink: 0;
                font-size: 14px;
                line-height: 16px;
                color: #979797;
            }
        }
    }

    &__preview-wrapper {
        flex-shrink: 0;
        width: 340px;
        box-sizing: border-box;
        background-color: #f9fafd;
        border-left: 1px solid #d8d8d8;
        overflow: hidden;
    }
    &__preview {
        padding: 10px 40px 20px;
        &-title {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            color: #787878;
            margin-bottom: 10px;
        }
    }
}
