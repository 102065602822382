@import '../../../../styles/mixins';

.text {
  font-size: 16px;
  color: #3c3c3c;
  line-height: 24px;
  @include max_rwd(1200) {
    padding-top: 40px;
  }
}
