.popover {
    z-index: 1000000;
}

.popoverContent {
    background-color: #fff;
    padding: 8px;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    max-height: 320px;
    overflow: auto;
    box-sizing: border-box;
    margin: 0 5px;
}

.parent {
    margin: 0 1px;
    height: 100%;
}
