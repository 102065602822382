.linkEditor {
    position: relative;
}

.mainBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgb(245, 245, 245);
    background-color: rgb(245, 245, 245);
    border-radius: 8px;
    padding: 6px;
    > svg {
        width: 16px;
        height: 16px;
    }
}

.isErrorInputBox {
    border-color: #ff5656;
}

.input {
    border: 0;
    outline: none;
    appearance: none;
    background-color: transparent;
    padding: 0;
    margin-left: 8px;
    width: 160px;
    font-size: 12px;
    color: #404040;
    &::placeholder {
        opacity: 0.5;
    }
}

.inputButton {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    border-radius: 8px;
    &:hover {
        opacity: 0.8;
    }
    > svg {
        width: 16px;
        height: 16px;
    }
}

.inputButtonAccept {
    background-color: #2196f3;
    margin: 0 4px 0 8px;
}
.inputButtonDelete {
    background-color: #ff5656;
}

.targetBox {
    margin-top: 12px;
    display: flex;
    align-items: center;
    font-size: 12px;
}
