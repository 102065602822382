@import '../../../../styles/mixins';

.editor-new {
    position: relative;

    > div.error {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 20px;
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        box-sizing: border-box;
    }

    > div.loading {
        position: absolute;
        z-index: 99999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
            max-width: 400px;
        }
    }

    .editor-panel {
        position: sticky;
        top: 60px;
        bottom: 0;
        width: 300px;
        height: calc(100vh - 60px);
        background-color: #fff;
        box-sizing: border-box;
        flex-shrink: 0;
        transition: transform 0.2s ease-in-out;
        @include max_rwd(1023) {
            top: 80px;
            min-height: calc(100vh - 80px);
        }
        @include max_rwd(768) {
            transform: translateX(100%);
            position: fixed;
        }
        h3 {
            box-sizing: border-box;
            height: 56px;
            display: flex;
            align-items: flex-end;
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            color: #3c3c3c;
            padding: 0 10px 14px 24px;
            position: relative;
            z-index: 3;
            &.is-sticky {
                box-shadow: 0 2px 9px rgba(0, 0, 0, 0.07);
            }
        }
        &--blocks {
            z-index: 1500;
            left: 0;
            transform: translateX(-100%);
            &.is-opened {
                transform: translateX(0);
            }
            .close {
                position: absolute;
                z-index: 10;
                top: 20px;
                right: 24px;
                width: 22px;
                height: 22px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url(./i/cross2.svg);
                cursor: pointer;
                transition: opacity 0.2s ease-in-out;
                &:hover {
                    opacity: 0.7;
                }
            }
            ul.list {
                margin: 0 24px 24px;
                > li {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    > .label {
                        > .label-text {
                            font-family: 'Roboto', sans-serif;
                            font-size: 14px;
                            color: #3c3c3c;
                            vertical-align: middle;
                        }
                        > .label-mark {
                            margin-left: 4px;
                            padding: 2px 6px;
                            font-size: 10px;
                            line-height: 12px;
                            color: #fff;
                            border-radius: 20px;
                            vertical-align: middle;
                        }
                    }
                    > .description {
                        font-family: 'Roboto', sans-serif;
                        font-size: 12px;
                        line-height: 16px;
                        color: #979797;
                        margin-top: 6px;
                        > .description-icon {
                            margin-left: 2px;
                            vertical-align: middle;
                            cursor: pointer;
                            transition: opacity 0.2s ease-in-out;
                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }
                    > ul.inner-list {
                        margin-top: 12px;
                        > li {
                            position: relative;
                            display: flex;
                            align-items: center;
                            box-sizing: border-box;
                            width: 100%;
                            margin-bottom: 6px;
                            background-color: #fff;
                            border-radius: 4px;
                            cursor: pointer;
                            font-size: 14px;
                            border: 1px solid #e1e1e1;
                            padding: 10px;
                            background-repeat: no-repeat;
                            background-position: calc(100% - 16px) 50%;
                            transition: border-color 0.2s ease-in-out, background-image 0.2s ease-in-out;
                            &:hover {
                                border-color: #787878;
                                &.can-collapse {
                                    background-image: url(./i/right-icon--hovered.svg);
                                }
                            }
                            &.can-collapse {
                                padding-right: 42px;
                                background-image: url(./i/right-icon.svg);
                            }
                            &.editor-panel--blocks--has-active-icon {
                                .img--active {
                                    display: none;
                                }
                                &:hover {
                                    .img {
                                        display: none;

                                        &--active {
                                            display: flex;
                                        }
                                    }
                                }
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                            > .img {
                                height: 28px;
                                width: 28px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-right: 12px;
                            }
                            > .mark {
                                width: 4px;
                                height: 4px;
                                position: absolute;
                                top: 3px;
                                left: 3px;
                                border-radius: 50%;
                            }
                            > p {
                                display: inline-block;
                                font-family: 'Roboto', sans-serif;
                                font-size: 14px;
                                color: #3c3c3c;
                                > span {
                                    padding: 2px 6px;
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin-left: 8px;
                                    border-radius: 20px;
                                    background-color: #785cc7;
                                    color: #fff;
                                    font-size: 10px;
                                    line-height: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &--controls {
            z-index: 100;
            right: 0;
            box-shadow: 0 1px 2px rgba(46, 35, 128, 0.15), 0 0 8px -2px rgba(46, 35, 128, 0.15);
            opacity: 1;

            &--is-opacity {
                opacity: 0;
                transition: opacity 0.4s ease-in-out;
            }
            &--is-secondary {
                position: fixed;
                @include min_rwd(769) {
                    animation-duration: 0.4s;
                    animation-timing-function: ease-out;
                    animation-name: slideInFromRight;
                }
            }

            @include max_rwd(1159) {
                width: 240px;
            }
            @include max_rwd(768) {
                z-index: 150;
                width: 100%;
                max-width: calc(100% - 80px);
                &--opened {
                    z-index: 1000;
                    transform: translateX(0);
                }
            }
            ul.list {
                margin: 0 24px 80px;
                > li {
                    padding-bottom: 21px;
                    margin-bottom: 20px;
                    position: relative;
                    &.disabled {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: -24px;
                        right: -24px;
                        height: 1px;
                        background-color: #d8d8d8;
                    }
                    &:last-child {
                        padding: 0;
                        margin: 0;
                        &:after {
                            display: none;
                        }
                    }
                    > p.group-label {
                        font-family: 'Roboto', sans-serif;
                        font-size: 14px;
                        color: #3c3c3c;
                        margin-bottom: 16px;
                    }
                    > ul {
                        > li {
                            margin-bottom: 16px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            .control-box {
                                &.flexed {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    > p.label {
                                        margin-bottom: 0;
                                    }
                                    > div {
                                        cursor: pointer;
                                        display: flex;
                                        align-items: center;
                                        font-family: 'Roboto', sans-serif;
                                        font-size: 14px;
                                        color: #2990fb;
                                        transition: opacity 0.2s ease-in-out;
                                        padding-left: 10px;
                                        &:hover {
                                            opacity: 0.8;
                                        }
                                        > img {
                                            margin-right: 4px;
                                        }
                                    }
                                }
                                &.disabled {
                                    opacity: 0.5;
                                    pointer-events: none;
                                }
                                > p.label {
                                    font-family: 'Roboto', sans-serif;
                                    font-size: 14px;
                                    color: #3c3c3c;
                                    margin-bottom: 8px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    line-height: 16px;

                                    > span.link {
                                        display: inline-block;
                                        font-family: 'Roboto', sans-serif;
                                        font-size: 14px;
                                        color: #2990fb;
                                        margin-left: auto;
                                        cursor: pointer;
                                        vertical-align: text-top;
                                    }
                                }
                                > span.link {
                                    display: inline-block;
                                    font-family: 'Roboto', sans-serif;
                                    font-size: 14px;
                                    color: #2990fb;
                                    margin-bottom: 8px;
                                    cursor: pointer;
                                    vertical-align: text-top;
                                }
                                > div.content {
                                    &.input {
                                        input {
                                            appearance: none;
                                            font-family: 'Roboto', sans-serif;
                                            font-size: 14px;
                                            color: #3c3c3c;
                                            border: 1px solid #d8d8d8;
                                            box-sizing: border-box;
                                            border-radius: 4px;
                                            width: 100%;
                                            height: 32px;
                                            padding: 0 8px;
                                            outline: none;
                                            &.is-error {
                                                border-color: #ff5656;
                                            }
                                            &:focus {
                                                border-color: #69b1fc !important;
                                                box-shadow: inset 0 0 0 1px #69b1fc;
                                            }
                                        }
                                    }
                                    &.textarea {
                                        textarea {
                                            appearance: none;
                                            font-family: 'Roboto', sans-serif;
                                            font-size: 14px;
                                            color: #3c3c3c;
                                            border: 1px solid #d8d8d8;
                                            box-sizing: border-box;
                                            border-radius: 4px;
                                            width: 100%;
                                            outline: none;
                                            resize: none;
                                            padding: 5px 12px 6px;
                                            &:focus {
                                                border-color: #69b1fc !important;
                                                box-shadow: inset 0 0 0 1px #69b1fc;
                                            }
                                        }
                                    }
                                    &.checkbox {
                                        display: flex;
                                        align-items: flex-start;
                                        > div.checkbox-block {
                                            width: 14px;
                                            height: 14px;
                                            border-radius: 2px;
                                            cursor: pointer;
                                            margin-right: 8px;
                                            flex-shrink: 0;
                                            background-color: #d7d7d7;
                                            background-position: 50% 3px;
                                            background-repeat: no-repeat;
                                            &.is-checked {
                                                background-color: #2990fb;
                                                background-image: url('./i/checkmark.svg');
                                            }
                                        }
                                        > p {
                                            cursor: pointer;
                                            font-family: 'Roboto', sans-serif;
                                            font-size: 14px;
                                            line-height: 16px;
                                            color: #3c3c3c;
                                            display: flex;
                                            align-items: center;
                                        }
                                    }
                                    &.radio {
                                        display: flex;
                                        align-items: flex-start;
                                        flex-direction: column;
                                        > div.radio-option {
                                            cursor: pointer;
                                            font-family: 'Roboto', sans-serif;
                                            font-size: 14px;
                                            color: #3c3c3c;
                                            display: flex;
                                            align-items: center;
                                            margin-right: 12px;
                                            margin-bottom: 8px;
                                            &:before {
                                                content: '';
                                                width: 14px;
                                                height: 14px;
                                                margin-right: 8px;
                                                background-image: url('./i/radio_button_normal.svg');
                                                flex-shrink: 0;
                                            }
                                            &.is-checked {
                                                &:before {
                                                    background-image: url('./i/radio_button_selected.svg');
                                                }
                                            }
                                        }
                                    }
                                }
                                > p.description {
                                    margin-top: 8px;
                                    font-family: 'Roboto', sans-serif;
                                    font-style: normal;
                                    font-weight: normal;
                                    font-size: 14px;
                                    line-height: 18px;
                                    color: #787878;
                                }
                            }
                        }
                    }
                }
            }
            p.empty {
                margin: 0 24px 24px;
                text-align: center;
                padding-top: 15px;
                font-family: 'Roboto', sans-serif;
                font-style: italic;
                font-size: 14px;
                opacity: 0.5;
            }
            [data-simplebar] {
                max-height: calc(100% - 56px);
                .simplebar-vertical {
                    > .simplebar-scrollbar {
                        &:before {
                            top: 0;
                            bottom: 24px;
                        }
                    }
                }
            }
        }

        [data-simplebar].blocks-content {
            max-height: calc(100% - 56px) !important;
            height: 100%;
            .simplebar-vertical {
                > .simplebar-scrollbar {
                    &:before {
                        top: 0;
                        bottom: 24px;
                    }
                }
            }
        }
    }
    .editor-panel-overlay {
        z-index: 1000;
        display: block;
        position: fixed;
        top: 60px;
        left: 0;
        height: calc(100% - 60px);
        width: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        @include max_rwd(1023) {
            top: 80px;
            min-height: calc(100vh - 80px);
        }
    }

    .artboard {
        position: relative;
        width: 100%;
        height: 100%;
        color: #333;
        min-height: calc(100vh - 60px);
        box-sizing: border-box;
        background-color: #f2f3f7;
        display: flex;
        justify-content: space-between;
        @include max_rwd(1023) {
            min-height: calc(100vh - 80px);
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            z-index: 30;
        }
        .preview.__mob {
            position: absolute;
            top: 10px;
            right: 30px;
            width: 320px;
            height: 600px;
            background-color: #fff;
        }
        .block_page {
            width: 100%;
            height: 100%;
            color: #000;
            border: 1px solid transparent;
            box-sizing: content-box;
            box-shadow: 0 1px 2px rgba(46, 35, 128, 0.15), 0 0 8px -2px rgba(46, 35, 128, 0.15);
            border-radius: 4px;
            background-position: top center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: #fff;
            position: relative;
            z-index: 50;
            &__overlay {
                position: absolute;
                z-index: 10;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: transparent;
            }
            &__container {
                padding: 40px 20px;
                position: relative;
                z-index: 100;
                width: 800px;
                max-width: calc(100% - 40px);
                min-height: auto;
                @include max_rwd(1455) {
                    margin-left: -140px;
                }
                @include max_rwd(768) {
                    margin-left: 0;
                    max-width: 100%;
                }
            }
        }
        .plus {
            position: absolute;
            left: 50%;
            margin-left: -12px;
            top: -12px;
            width: 24px;
            height: 24px;
            background-color: #979797;
            background-image: url(./i/plus.svg);
            border-radius: 99px;
            cursor: pointer;
            line-height: 24px;
            color: #fff;
            font-size: 24px;
            text-align: center;
            z-index: 1000;
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            &:hover {
                background-color: #787878;
            }
        }
        .delete {
            display: none;
            position: absolute;
            right: 5px;
            top: 5px;
            width: 30px;
            height: 30px;
            background-color: #bbb;
            border-radius: 99px;
            cursor: pointer;
            line-height: 26px;
            text-align: center;
            color: #fff;
        }
        .block-control {
            position: relative;
            z-index: 0;
            border-top: 1px dashed #888;
            border-bottom: 1px dashed #888;
            margin: 10px 0;
            font-size: 0;
            &:first-child {
                border-top: 0;
                margin-top: 0;
            }
            > .selected-border {
                display: none;
                position: absolute;
                top: 0;
                left: 0;
                pointer-events: none;
                width: 100%;
                height: 100%;
                outline: 2px solid #4891f3;
            }
            &.__selected {
                z-index: 1;
                > .selected-border {
                    display: block;
                }
                .plus {
                    opacity: 0 !important;
                    pointer-events: none !important;
                }
            }
        }
        .block-control:hover .plus,
        .plus:hover,
        .block-control:hover .delete,
        .delete:hover {
            opacity: 1;
            pointer-events: auto;
        }

        .placeholder {
            height: 150px;
            background-color: #eee;
        }
        .image_cnt {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            outline: none;
            overflow: hidden;
            box-sizing: border-box;
            border-style: solid;
            border-radius: 0;
            border-width: 0;
            border-color: rgb(255, 255, 255);
            img {
                position: absolute;
                top: -100%;
                left: -100%;
                right: -100%;
                bottom: -100%;
                margin: auto;
                opacity: 0;
                transform: scale(1.02);
                transition: opacity 1s linear, transform 1s linear;
                max-width: 100%;
                max-height: 100%;
                border: 0 none;
                user-select: none;
                backface-visibility: hidden;
                perspective: 1000;
                &.reveal {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
        .image_align_cnt {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            text-align: center;
        }
    }

    /*
     *** Quill hacks ***
    */
    .qt {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        width: 100%;
    }
    .qt.__readonly .ql-toolbar {
        display: none;
    }
    .quill {
        position: relative;
    }
    .quill .ql-toolbar {
        position: absolute;
        top: -52px;
        z-index: 10000;
        width: max-content;
        background-color: #fff;
        text-align: left;
        > .ql-formats {
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .ql-container.ql-snow {
        border: none !important;
    }
    .ql-tooltip.ql-editing {
        z-index: 99;
    }
    .ql-font .ql-picker-options {
        max-height: 200px;
        overflow-y: scroll;
    }
    .quill strong {
        font-weight: bold;
    }
    .quill em {
        font-style: italic;
    }
    .ql-tooltip {
        z-index: 10;
    }
    .ql-picker-label {
        overflow: hidden;
    }
    .ql-editor {
        padding: 0 !important;
        overflow-y: hidden !important;
    }
    .ql-container.ql-snow .ql-editor a {
        text-decoration: none;
        color: #06c;
        &:hover {
            text-decoration: underline;
        }
    }

    .ql-toolbar.ql-snow .ql-picker-options {
        max-height: 400px;
        overflow-y: auto;
        text-align: left;
    }
    .ql-snow .ql-picker-label::before {
        display: block !important;
        max-width: calc(100% - 18px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .ql-snow .ql-picker,
    .ql-snow .ql-picker-label {
        outline: none !important;
        //padding-left: 0;
        //margin-right: 5px;
        //border: 0;
    }

    /* * Fonts * */
    .ql-snow .ql-picker.ql-font {
        width: 140px !important;
    }
    /* Roboto */
    .ql-font span[data-value='Roboto']::before {
        font-family: 'Roboto', sans-serif;
        content: 'Roboto' !important;
    }
    .ql-font-Roboto {
        font-family: 'Roboto', sans-serif;
    }
    /* Roboto Condensed */
    .ql-font span[data-value='RobotoCondensed']::before {
        font-family: 'Roboto Condensed', sans-serif;
        content: 'Roboto Condensed' !important;
    }
    .ql-font-RobotoCondensed {
        font-family: 'Roboto Condensed', sans-serif;
    }
    /* Open Sans */
    .ql-font span[data-value='OpenSans']::before {
        font-family: 'Open Sans', sans-serif;
        content: 'Open Sans' !important;
    }
    .ql-font-OpenSans {
        font-family: 'Open Sans', sans-serif;
    }
    /* Open Sans Condensed */
    .ql-font span[data-value='OpenSansCondensed']::before {
        font-family: 'Open Sans Condensed', sans-serif;
        content: 'Open Sans Condensed' !important;
    }
    .ql-font-OpenSansCondensed {
        font-family: 'Open Sans Condensed', sans-serif;
    }
    /* Lato */
    .ql-font span[data-value='Lato']::before {
        font-family: 'Lato', sans-serif;
        content: 'Lato' !important;
    }
    .ql-font-Lato {
        font-family: 'Lato', sans-serif;
    }
    /* Montserrat */
    .ql-font span[data-value='Montserrat']::before {
        font-family: 'Montserrat', sans-serif;
        content: 'Montserrat' !important;
    }
    .ql-font-Montserrat {
        font-family: 'Montserrat', sans-serif;
    }
    /* Oswald */
    .ql-font span[data-value='Oswald']::before {
        font-family: 'Oswald', sans-serif;
        content: 'Oswald' !important;
    }
    .ql-font-Oswald {
        font-family: 'Oswald', sans-serif;
    }
    /* Merriweather */
    .ql-font span[data-value='Merriweather']::before {
        font-family: 'Merriweather', sans-serif;
        content: 'Merriweather' !important;
    }
    .ql-font-Merriweather {
        font-family: 'Merriweather', sans-serif;
    }
    /* Ubuntu */
    .ql-font span[data-value='Ubuntu']::before {
        font-family: 'Ubuntu', sans-serif;
        content: 'Ubuntu' !important;
    }
    .ql-font-Ubuntu {
        font-family: 'Ubuntu', sans-serif;
    }
    /* Lobster */
    .ql-font span[data-value='Lobster']::before {
        font-family: 'Lobster', sans-serif;
        content: 'Lobster' !important;
    }
    .ql-font-Lobster {
        font-family: 'Lobster', sans-serif;
    }
    /* Pacifico */
    .ql-font span[data-value='Pacifico']::before {
        font-family: 'Pacifico', sans-serif;
        content: 'Pacifico' !important;
    }
    .ql-font-Pacifico {
        font-family: 'Pacifico', sans-serif;
    }
    /* Vollkorn */
    .ql-font span[data-value='Vollkorn']::before {
        font-family: 'Vollkorn', sans-serif;
        content: 'Vollkorn' !important;
    }
    .ql-font-Vollkorn {
        font-family: 'Vollkorn', sans-serif;
    }
    /* Cuprum */
    .ql-font span[data-value='Cuprum']::before {
        font-family: 'Cuprum', sans-serif;
        content: 'Cuprum' !important;
    }
    .ql-font-Cuprum {
        font-family: 'Cuprum', sans-serif;
    }
    /* Alegreya Sans */
    .ql-font span[data-value='AlegreyaSans']::before {
        font-family: 'Alegreya Sans', sans-serif;
        content: 'Alegreya Sans' !important;
    }
    .ql-font-AlegreyaSans {
        font-family: 'Alegreya Sans', sans-serif;
    }
    /* Russo One */
    .ql-font span[data-value='RussoOne']::before {
        font-family: 'Russo One', sans-serif;
        content: 'Russo One' !important;
    }
    .ql-font-RussoOne {
        font-family: 'Russo One', sans-serif;
    }
    /* Playfair Display */
    .ql-font span[data-value='PlayfairDisplay']::before {
        font-family: 'Playfair Display', sans-serif;
        content: 'Playfair Display' !important;
    }
    .ql-font-PlayfairDisplay {
        font-family: 'Playfair Display', sans-serif;
    }
    /* Playfair Display SC */
    .ql-font span[data-value='PlayfairDisplaySC']::before {
        font-family: 'Playfair Display SC', sans-serif;
        content: 'Playfair Display SC' !important;
    }
    .ql-font-PlayfairDisplaySC {
        font-family: 'Playfair Display SC', sans-serif;
    }
    /* Alice */
    .ql-font span[data-value='Alice']::before {
        font-family: 'Alice', sans-serif;
        content: 'Alice' !important;
    }
    .ql-font-Alice {
        font-family: 'Alice', sans-serif;
    }
    /* Press Start 2P */
    .ql-font span[data-value='PressStart2P']::before {
        font-family: 'Press Start 2P', sans-serif;
        content: 'Press Start 2P' !important;
    }
    .ql-font-PressStart2P {
        font-family: 'Press Start 2P', sans-serif;
    }
    /* Bad Script */
    .ql-font span[data-value='BadScript']::before {
        font-family: 'Bad Script', sans-serif;
        content: 'Bad Script' !important;
    }
    .ql-font-BadScript {
        font-family: 'Bad Script', sans-serif;
    }
    /* Yeseva One */
    .ql-font span[data-value='YesevaOne']::before {
        font-family: 'Yeseva One', sans-serif;
        content: 'Yeseva One' !important;
    }
    .ql-font-YesevaOne {
        font-family: 'Yeseva One', sans-serif;
    }
    /* Marmelad */
    .ql-font span[data-value='Marmelad']::before {
        font-family: 'Marmelad', sans-serif;
        content: 'Marmelad' !important;
    }
    .ql-font-Marmelad {
        font-family: 'Marmelad', sans-serif;
    }
    /* Rubik Mono One */
    .ql-font span[data-value='RubikMonoOne']::before {
        font-family: 'Rubik Mono One', sans-serif;
        content: 'Rubik Mono One' !important;
    }
    .ql-font-RubikMonoOne {
        font-family: 'Rubik Mono One', sans-serif;
    }
    /* Raleway */
    .ql-font span[data-value='Raleway']::before {
        font-family: 'Raleway', sans-serif;
        content: 'Raleway' !important;
    }
    .ql-font-Raleway {
        font-family: 'Raleway', sans-serif;
    }
    /* Roboto Slab */
    .ql-font span[data-value='RobotoSlab']::before {
        font-family: 'Roboto Slab', sans-serif;
        content: 'Roboto Slab' !important;
    }
    .ql-font-RobotoSlab {
        font-family: 'Roboto Slab', sans-serif;
    }
    /* Lora */
    .ql-font span[data-value='Lora']::before {
        font-family: 'Lora', sans-serif;
        content: 'Lora' !important;
    }
    .ql-font-Lora {
        font-family: 'Lora', sans-serif;
    }
    /* Seymour One */
    .ql-font span[data-value='SeymourOne']::before {
        font-family: 'Seymour One', sans-serif;
        content: 'Seymour One' !important;
    }
    .ql-font-SeymourOne {
        font-family: 'Seymour One', sans-serif;
    }
    /* Cormorant SC */
    .ql-font span[data-value='CormorantSC']::before {
        font-family: 'Cormorant SC', sans-serif;
        content: 'Cormorant SC' !important;
    }
    .ql-font-CormorantSC {
        font-family: 'Cormorant SC', sans-serif;
    }
    /* Literata */
    .ql-font span[data-value='Literata']::before {
        font-family: 'Literata', sans-serif;
        content: 'Literata' !important;
    }
    .ql-font-Literata {
        font-family: 'Literata', sans-serif;
    }
    /* Spectral */
    .ql-font span[data-value='Spectral']::before {
        font-family: 'Spectral', sans-serif;
        content: 'Spectral' !important;
    }
    .ql-font-Spectral {
        font-family: 'Spectral', sans-serif;
    }
    /* Alegreya */
    .ql-font span[data-value='Alegreya']::before {
        font-family: 'Alegreya', sans-serif;
        content: 'Alegreya' !important;
    }
    .ql-font-Alegreya {
        font-family: 'Alegreya', sans-serif;
    }
    /* EB Garamond */
    .ql-font span[data-value='EBGaramond']::before {
        font-family: 'EB Garamond', sans-serif;
        content: 'EB Garamond' !important;
    }
    .ql-font-EBGaramond {
        font-family: 'EB Garamond', sans-serif;
    }
    /* Bitter */
    .ql-font span[data-value='Bitter']::before {
        font-family: 'Bitter', sans-serif;
        content: 'Bitter' !important;
    }
    .ql-font-Bitter {
        font-family: 'Bitter', sans-serif;
    }
    /* PT Serif */
    .ql-font span[data-value='PTSerif']::before {
        font-family: 'PT Serif', sans-serif;
        content: 'PT Serif' !important;
    }
    .ql-font-PTSerif {
        font-family: 'PT Serif', sans-serif;
    }
    /* Noto Sans */
    .ql-font span[data-value='NotoSans']::before {
        font-family: 'Noto Sans', sans-serif;
        content: 'Noto Sans' !important;
    }
    .ql-font-NotoSans {
        font-family: 'Noto Sans', sans-serif;
    }
    /* Manrope */
    .ql-font span[data-value='Manrope']::before {
        font-family: 'Manrope', sans-serif;
        content: 'Manrope' !important;
    }
    .ql-font-Manrope {
        font-family: 'Manrope', sans-serif;
    }
    /* Unbounded */
    .ql-font span[data-value='Unbounded']::before {
        font-family: 'Unbounded', sans-serif;
        content: 'Unbounded' !important;
    }
    .ql-font-Unbounded {
        font-family: 'Unbounded', sans-serif;
    }
    /* Arimo */
    .ql-font span[data-value='Arimo']::before {
        font-family: 'Arimo', sans-serif;
        content: 'Arimo' !important;
    }
    .ql-font-Arimo {
        font-family: 'Arimo', sans-serif;
    }
    /* Jost */
    .ql-font span[data-value='Jost']::before {
        font-family: 'Jost', sans-serif;
        content: 'Jost' !important;
    }
    .ql-font-Jost {
        font-family: 'Jost', sans-serif;
    }
    /* Overpass */
    .ql-font span[data-value='Overpass']::before {
        font-family: 'Overpass', sans-serif;
        content: 'Overpass' !important;
    }
    .ql-font-Overpass {
        font-family: 'Overpass', sans-serif;
    }
    /* Comfortaa */
    .ql-font span[data-value='Comfortaa']::before {
        font-family: 'Comfortaa', sans-serif;
        content: 'Comfortaa' !important;
    }
    .ql-font-Comfortaa {
        font-family: 'Comfortaa', sans-serif;
    }
    /* Caveat */
    .ql-font span[data-value='Caveat']::before {
        font-family: 'Caveat', sans-serif;
        content: 'Caveat' !important;
    }
    .ql-font-Caveat {
        font-family: 'Caveat', sans-serif;
    }
    /* Inter Tight */
    .ql-font span[data-value='InterTight']::before {
        font-family: 'Inter Tight', sans-serif;
        content: 'Inter Tight' !important;
    }
    .ql-font-InterTight {
        font-family: 'Inter Tight', sans-serif;
    }
    /* Amatic SC */
    .ql-font span[data-value='AmaticSC']::before {
        font-family: 'Amatic SC', sans-serif;
        content: 'Amatic SC' !important;
    }
    .ql-font-AmaticSC {
        font-family: 'Amatic SC', sans-serif;
    }
    /* Tinos */
    .ql-font span[data-value='Tinos']::before {
        font-family: 'Tinos', sans-serif;
        content: 'Tinos' !important;
    }
    .ql-font-Tinos {
        font-family: 'Tinos', sans-serif;
    }
    /* Prata */
    .ql-font span[data-value='Prata']::before {
        font-family: 'Prata', sans-serif;
        content: 'Prata' !important;
    }
    .ql-font-Prata {
        font-family: 'Prata', sans-serif;
    }
    /* Philosopher */
    .ql-font span[data-value='Philosopher']::before {
        font-family: 'Philosopher', sans-serif;
        content: 'Philosopher' !important;
    }
    .ql-font-Philosopher {
        font-family: 'Philosopher', sans-serif;
    }
    /* Geologica */
    .ql-font span[data-value='Geologica']::before {
        font-family: 'Geologica', sans-serif;
        content: 'Geologica' !important;
    }
    .ql-font-Geologica {
        font-family: 'Geologica', sans-serif;
    }
    /* Tenor Sans */
    .ql-font span[data-value='TenorSans']::before {
        font-family: 'Tenor Sans', sans-serif;
        content: 'Tenor Sans' !important;
    }
    .ql-font-TenorSans {
        font-family: 'Tenor Sans', sans-serif;
    }
    /* Advent Pro */
    .ql-font span[data-value='AdventPro']::before {
        font-family: 'Advent Pro', sans-serif;
        content: 'Advent Pro' !important;
    }
    .ql-font-AdventPro {
        font-family: 'Advent Pro', sans-serif;
    }
    /* Poiret One */
    .ql-font span[data-value='PoiretOne']::before {
        font-family: 'Poiret One', sans-serif;
        content: 'Poiret One' !important;
    }
    .ql-font-PoiretOne {
        font-family: 'Poiret One', sans-serif;
    }
    /* Jura */
    .ql-font span[data-value='Jura']::before {
        font-family: 'Jura', sans-serif;
        content: 'Jura' !important;
    }
    .ql-font-Jura {
        font-family: 'Jura', sans-serif;
    }
    /* PT Sans Narrow */
    .ql-font span[data-value='PTSansNarrow']::before {
        font-family: 'PT Sans Narrow', sans-serif;
        content: 'PT Sans Narrow' !important;
    }
    .ql-font-PTSansNarrow {
        font-family: 'PT Sans Narrow', sans-serif;
    }
    /* Fira Sans */
    .ql-font span[data-value='FiraSans']::before {
        font-family: 'Fira Sans', sans-serif;
        content: 'Fira Sans' !important;
    }
    .ql-font-FiraSans {
        font-family: 'Fira Sans', sans-serif;
    }
    /* Mulish */
    .ql-font span[data-value='Mulish']::before {
        font-family: 'Mulish', sans-serif;
        content: 'Mulish' !important;
    }
    .ql-font-Mulish {
        font-family: 'Mulish', sans-serif;
    }
    /* Nunito Sans */
    .ql-font span[data-value='NunitoSans']::before {
        font-family: 'Nunito Sans', sans-serif;
        content: 'Nunito Sans' !important;
    }
    .ql-font-NunitoSans {
        font-family: 'Nunito Sans', sans-serif;
    }
    /* Inter */
    .ql-font span[data-value='Inter']::before {
        font-family: 'Inter', sans-serif;
        content: 'Inter' !important;
    }
    .ql-font-Inter {
        font-family: 'Inter', sans-serif;
    }
    /* Old Standard TT */
    .ql-font span[data-value='OldStandardTT']::before {
        font-family: 'Old Standard TT', sans-serif;
        content: 'Old Standard TT' !important;
    }
    .ql-font-OldStandardTT {
        font-family: 'Old Standard TT', sans-serif;
    }
    /* Ruda */
    .ql-font span[data-value='Ruda']::before {
        font-family: 'Ruda', sans-serif;
        content: 'Ruda' !important;
    }
    .ql-font-Ruda {
        font-family: 'Ruda', sans-serif;
    }
    /* Tenor Sans */
    .ql-font span[data-value='TenorSans']::before {
        font-family: 'Tenor Sans', sans-serif;
        content: 'Tenor Sans' !important;
    }
    .ql-font-TenorSans {
        font-family: 'Tenor Sans', sans-serif;
    }
    /* Source Code Pro */
    .ql-font span[data-value='SourceCodePro']::before {
        font-family: 'Source Code Pro', sans-serif;
        content: 'Source Code Pro' !important;
    }
    .ql-font-SourceCodePro {
        font-family: 'Source Code Pro', sans-serif;
    }
    /* Source Sans 3 */
    .ql-font span[data-value='SourceSans3']::before {
        font-family: 'Source Sans 3', sans-serif;
        content: 'Source Sans 3' !important;
    }
    .ql-font-SourceSans3 {
        font-family: 'Source Sans 3', sans-serif;
    }
    /* League Gothic */
    .ql-font span[data-value='LeagueGothic']::before {
        font-family: 'League Gothic', sans-serif;
        content: 'League Gothic' !important;
    }
    .ql-font-LeagueGothic {
        font-family: 'League Gothic', sans-serif;
    }
    /* Belleza */
    .ql-font span[data-value='Belleza']::before {
        font-family: 'Belleza', sans-serif;
        content: 'Belleza' !important;
    }
    .ql-font-Belleza {
        font-family: 'Belleza', sans-serif;
    }
    /* Lancelot */
    .ql-font span[data-value='Lancelot']::before {
        font-family: 'Lancelot', sans-serif;
        content: 'Lancelot' !important;
    }
    .ql-font-Lancelot {
        font-family: 'Lancelot', sans-serif;
    }
    /* Schoolbell */
    .ql-font span[data-value='Schoolbell']::before {
        font-family: 'Schoolbell', sans-serif;
        content: 'Schoolbell' !important;
    }
    .ql-font-Schoolbell {
        font-family: 'Schoolbell', sans-serif;
    }
    /* Grandstander */
    .ql-font span[data-value='Grandstander']::before {
        font-family: 'Grandstander', sans-serif;
        content: 'Grandstander' !important;
    }
    .ql-font-Grandstander {
        font-family: 'Grandstander', sans-serif;
    }
    /* Chewy */
    .ql-font span[data-value='Chewy']::before {
        font-family: 'Chewy', sans-serif;
        content: 'Chewy' !important;
    }
    .ql-font-Chewy {
        font-family: 'Chewy', sans-serif;
    }
    /* Barriecito */
    .ql-font span[data-value='Barriecito']::before {
        font-family: 'Barriecito', sans-serif;
        content: 'Barriecito' !important;
    }
    .ql-font-Barriecito {
        font-family: 'Barriecito', sans-serif;
    }
    /* Arapey */
    .ql-font span[data-value='Arapey']::before {
        font-family: 'Arapey', sans-serif;
        content: 'Arapey' !important;
    }
    .ql-font-Arapey {
        font-family: 'Arapey', sans-serif;
    }
    /* Libre Bodoni */
    .ql-font span[data-value='LibreBodoni']::before {
        font-family: 'Libre Bodoni', sans-serif;
        content: 'Libre Bodoni' !important;
    }
    .ql-font-LibreBodoni {
        font-family: 'Libre Bodoni', sans-serif;
    }
    /* Cinzel Decorative */
    .ql-font span[data-value='CinzelDecorative']::before {
        font-family: 'Cinzel Decorative', sans-serif;
        content: 'Cinzel Decorative' !important;
    }
    .ql-font-CinzelDecorative {
        font-family: 'Cinzel Decorative', sans-serif;
    }
    /* Fredoka */
    .ql-font span[data-value='Fredoka']::before {
        font-family: 'Fredoka', sans-serif;
        content: 'Fredoka' !important;
    }
    .ql-font-Fredoka {
        font-family: 'Fredoka', sans-serif;
    }
    /* Poppins */
    .ql-font span[data-value='Poppins']::before {
        font-family: 'Poppins', sans-serif;
        content: 'Poppins' !important;
    }
    .ql-font-Poppins {
        font-family: 'Poppins', sans-serif;
    }
    /* Arapey */
    .ql-font span[data-value='Arapey']::before {
        font-family: 'Arapey', sans-serif;
        content: 'Arapey' !important;
    }
    .ql-font-Arapey {
        font-family: 'Arapey', sans-serif;
    }
    /* Outfit */
    .ql-font span[data-value='Outfit']::before {
        font-family: 'Outfit', sans-serif;
        content: 'Outfit' !important;
    }
    .ql-font-Outfit {
        font-family: 'Outfit', sans-serif;
    }
    /* Schoolbell */
    .ql-font span[data-value='Schoolbell']::before {
        font-family: 'Schoolbell', sans-serif;
        content: 'Schoolbell' !important;
    }
    .ql-font-Schoolbell {
        font-family: 'Schoolbell', sans-serif;
    }
    /* Satisfy */
    .ql-font span[data-value='Satisfy']::before {
        font-family: 'Satisfy', sans-serif;
        content: 'Satisfy' !important;
    }
    .ql-font-Satisfy {
        font-family: 'Satisfy', sans-serif;
    }
    /* Sanchez */
    .ql-font span[data-value='Sanchez']::before {
        font-family: 'Sanchez', sans-serif;
        content: 'Sanchez' !important;
    }
    .ql-font-Sanchez {
        font-family: 'Sanchez', sans-serif;
    }
    /* Saira Condensed */
    .ql-font span[data-value='SairaCondensed']::before {
        font-family: 'Saira Condensed', sans-serif;
        content: 'Saira Condensed' !important;
    }
    .ql-font-SairaCondensed {
        font-family: 'Saira Condensed', sans-serif;
    }
    /* Rye */
    .ql-font span[data-value='Rye']::before {
        font-family: 'Rye', sans-serif;
        content: 'Rye' !important;
    }
    .ql-font-Rye {
        font-family: 'Rye', sans-serif;
    }
    /* Rosario */
    .ql-font span[data-value='Rosario']::before {
        font-family: 'Rosario', sans-serif;
        content: 'Rosario' !important;
    }
    .ql-font-Rosario {
        font-family: 'Rosario', sans-serif;
    }
    /* Ribeye */
    .ql-font span[data-value='Ribeye']::before {
        font-family: 'Ribeye', sans-serif;
        content: 'Ribeye' !important;
    }
    .ql-font-Ribeye {
        font-family: 'Ribeye', sans-serif;
    }
    /* Reggae One */
    .ql-font span[data-value='ReggaeOne']::before {
        font-family: 'Reggae One', sans-serif;
        content: 'Reggae One' !important;
    }
    .ql-font-ReggaeOne {
        font-family: 'Reggae One', sans-serif;
    }
    /* Red Hat Display */
    .ql-font span[data-value='RedHatDisplay']::before {
        font-family: 'Red Hat Display', sans-serif;
        content: 'Red Hat Display' !important;
    }
    .ql-font-RedHatDisplay {
        font-family: 'Red Hat Display', sans-serif;
    }
    /* Rakkas */
    .ql-font span[data-value='Rakkas']::before {
        font-family: 'Rakkas', sans-serif;
        content: 'Rakkas' !important;
    }
    .ql-font-Rakkas {
        font-family: 'Rakkas', sans-serif;
    }
    /* Radley */
    .ql-font span[data-value='Radley']::before {
        font-family: 'Radley', sans-serif;
        content: 'Radley' !important;
    }
    .ql-font-Radley {
        font-family: 'Radley', sans-serif;
    }
    /* Racing Sans One */
    .ql-font span[data-value='RacingSansOne']::before {
        font-family: 'Racing Sans One', sans-serif;
        content: 'Racing Sans One' !important;
    }
    .ql-font-RacingSansOne {
        font-family: 'Racing Sans One', sans-serif;
    }
    /* Quicksand */
    .ql-font span[data-value='Quicksand']::before {
        font-family: 'Quicksand', sans-serif;
        content: 'Quicksand' !important;
    }
    .ql-font-Quicksand {
        font-family: 'Quicksand', sans-serif;
    }
    /* Quattrocento */
    .ql-font span[data-value='Quattrocento']::before {
        font-family: 'Quattrocento', sans-serif;
        content: 'Quattrocento' !important;
    }
    .ql-font-Quattrocento {
        font-family: 'Quattrocento', sans-serif;
    }
    /* Quando */
    .ql-font span[data-value='Quando']::before {
        font-family: 'Quando', sans-serif;
        content: 'Quando' !important;
    }
    .ql-font-Quando {
        font-family: 'Quando', sans-serif;
    }
    /* Squada One */
    .ql-font span[data-value='SquadaOne']::before {
        font-family: 'Squada One', sans-serif;
        content: 'Squada One' !important;
    }
    .ql-font-SquadaOne {
        font-family: 'Squada One', sans-serif;
    }
    /* Prata */
    .ql-font span[data-value='Prata']::before {
        font-family: 'Prata', sans-serif;
        content: 'Prata' !important;
    }
    .ql-font-Prata {
        font-family: 'Prata', sans-serif;
    }
    /* Podkova */
    .ql-font span[data-value='Podkova']::before {
        font-family: 'Podkova', sans-serif;
        content: 'Podkova' !important;
    }
    .ql-font-Podkova {
        font-family: 'Podkova', sans-serif;
    }
    /* Shantell Sans */
    .ql-font span[data-value='ShantellSans']::before {
        font-family: 'Shantell Sans', sans-serif;
        content: 'Shantell Sans' !important;
    }
    .ql-font-ShantellSans {
        font-family: 'Shantell Sans', sans-serif;
    }
    /* Signika Negative */
    .ql-font span[data-value='SignikaNegative']::before {
        font-family: 'Signika Negative', sans-serif;
        content: 'Signika Negative' !important;
    }
    .ql-font-SignikaNegative {
        font-family: 'Signika Negative', sans-serif;
    }
    /* Sirin Stencil */
    .ql-font span[data-value='SirinStencil']::before {
        font-family: 'Sirin Stencil', sans-serif;
        content: 'Sirin Stencil' !important;
    }
    .ql-font-SirinStencil {
        font-family: 'Sirin Stencil', sans-serif;
    }
    /* Six Caps */
    .ql-font span[data-value='SixCaps']::before {
        font-family: 'Six Caps', sans-serif;
        content: 'Six Caps' !important;
    }
    .ql-font-SixCaps {
        font-family: 'Six Caps', sans-serif;
    }
    /* Sniglet */
    .ql-font span[data-value='Sniglet']::before {
        font-family: 'Sniglet', sans-serif;
        content: 'Sniglet' !important;
    }
    .ql-font-Sniglet {
        font-family: 'Sniglet', sans-serif;
    }
    /* Solway */
    .ql-font span[data-value='Solway']::before {
        font-family: 'Solway', sans-serif;
        content: 'Solway' !important;
    }
    .ql-font-Solway {
        font-family: 'Solway', sans-serif;
    }
    /* Source Serif 4 */
    .ql-font span[data-value='SourceSerif4']::before {
        font-family: 'Source Serif 4', sans-serif;
        content: 'Source Serif 4' !important;
    }
    .ql-font-SourceSerif4 {
        font-family: 'Source Serif 4', sans-serif;
    }
    /* Space Mono */
    .ql-font span[data-value='SpaceMono']::before {
        font-family: 'Space Mono', sans-serif;
        content: 'Space Mono' !important;
    }
    .ql-font-SpaceMono {
        font-family: 'Space Mono', sans-serif;
    }
    /* Special Elite */
    .ql-font span[data-value='SpecialElite']::before {
        font-family: 'Special Elite', sans-serif;
        content: 'Special Elite' !important;
    }
    .ql-font-SpecialElite {
        font-family: 'Special Elite', sans-serif;
    }
    /* Squada One */
    .ql-font span[data-value='SquadaOne']::before {
        font-family: 'Squada One', sans-serif;
        content: 'Squada One' !important;
    }
    .ql-font-SquadaOne {
        font-family: 'Squada One', sans-serif;
    }
    /* Staatliches */
    .ql-font span[data-value='Staatliches']::before {
        font-family: 'Staatliches', sans-serif;
        content: 'Staatliches' !important;
    }
    .ql-font-Staatliches {
        font-family: 'Staatliches', sans-serif;
    }
    /* Tomorrow */
    .ql-font span[data-value='Tomorrow']::before {
        font-family: 'Tomorrow', sans-serif;
        content: 'Tomorrow' !important;
    }
    .ql-font-Tomorrow {
        font-family: 'Tomorrow', sans-serif;
    }
    /* Trocchi */
    .ql-font span[data-value='Trocchi']::before {
        font-family: 'Trocchi', sans-serif;
        content: 'Trocchi' !important;
    }
    .ql-font-Trocchi {
        font-family: 'Trocchi', sans-serif;
    }
    /* Trochut */
    .ql-font span[data-value='Trochut']::before {
        font-family: 'Trochut', sans-serif;
        content: 'Trochut' !important;
    }
    .ql-font-Trochut {
        font-family: 'Trochut', sans-serif;
    }

    /* * Line height * */
    .ql-snow .ql-picker.ql-lineheight {
        width: 64px !important;
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1']::before {
        content: '1.0';
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1']::before {
        content: '1.0' !important;
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.2']::before {
        content: '1.2';
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.2']::before {
        content: '1.2' !important;
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before {
        content: '1.5';
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.5']::before {
        content: '1.5' !important;
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.6']::before {
        content: '1.6';
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.6']::before {
        content: '1.6' !important;
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.8']::before {
        content: '1.8';
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.8']::before {
        content: '1.8' !important;
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2']::before {
        content: '2.0';
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2']::before {
        content: '2.0' !important;
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.4']::before {
        content: '2.4';
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.4']::before {
        content: '2.4' !important;
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.8']::before {
        content: '2.8';
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.8']::before {
        content: '2.8' !important;
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='3.0']::before {
        content: '3.0';
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='3.0']::before {
        content: '3.0' !important;
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='4.0']::before {
        content: '4.0';
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='4.0']::before {
        content: '4.0' !important;
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='5.0']::before {
        content: '5.0';
    }
    .ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='5.0']::before {
        content: '5.0' !important;
    }

    /* * Font size * */
    .ql-snow .ql-picker.ql-fontsize {
        width: 64px !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='8px']::before {
        content: '8px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='8px']::before {
        content: '8px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='9px']::before {
        content: '9px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='9px']::before {
        content: '9px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='10px']::before {
        content: '10px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='10px']::before {
        content: '10px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='11px']::before {
        content: '11px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='11px']::before {
        content: '11px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='12px']::before {
        content: '12px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='12px']::before {
        content: '12px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='14px']::before {
        content: '14px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='14px']::before {
        content: '14px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='16px']::before {
        content: '16px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='16px']::before {
        content: '16px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='18px']::before {
        content: '18px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='18px']::before {
        content: '18px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='20px']::before {
        content: '20px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='20px']::before {
        content: '20px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='24px']::before {
        content: '24px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='24px']::before {
        content: '24px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='30px']::before {
        content: '30px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='30px']::before {
        content: '30px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='36px']::before {
        content: '36px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='36px']::before {
        content: '36px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='48px']::before {
        content: '48px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='48px']::before {
        content: '48px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='60px']::before {
        content: '60px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='60px']::before {
        content: '60px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='72px']::before {
        content: '72px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='72px']::before {
        content: '72px' !important;
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-item[data-value='96px']::before {
        content: '96px';
    }
    .ql-snow .ql-picker.ql-fontsize .ql-picker-label[data-value='96px']::before {
        content: '96px' !important;
    }

    .ql-snow .ql-color-picker .ql-picker-item {
        border-radius: 2px;
    }

    // Custom Color
    .ql-picker .ql-picker-options [data-value='custom-color'] {
        width: calc(100% - 4px) !important;
        height: 24px !important;
        text-align: center;
        margin: 2px 0 2px 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
        background-color: #2990fb !important;
        color: #fff;
        transition: opacity 0.2s ease-in-out;
        border: 0;
        position: relative;
        &:hover {
            opacity: 0.8;
        }
    }
    .ql-picker .ql-picker-options [data-value='custom-color']:before {
        content: 'Custom Color';
        font-size: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: 18px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath d='M2 12.0261C2 17.1723 5.86713 21.413 10.8468 21.9863C11.5816 22.0709 12.2938 21.7576 12.8168 21.2333C13.4703 20.5781 13.4703 19.5159 12.8168 18.8607C12.2938 18.3364 11.8674 17.5541 12.2619 16.9268C13.8385 14.4192 22 20.178 22 12.0261C22 6.48884 17.5228 2 12 2C6.47715 2 2 6.48884 2 12.0261Z' stroke='%23ffffff' stroke-width='1.5'%3E%3C/path%3E%3Ccircle cx='17.5' cy='11.5' r='0.75' stroke='%23ffffff' stroke-width='1.5'%3E%3C/circle%3E%3Ccircle cx='6.5' cy='11.5' r='0.75' stroke='%23ffffff' stroke-width='1.5'%3E%3C/circle%3E%3Cpath d='M10.335 6.99976C10.335 7.41397 9.99917 7.74976 9.58496 7.74976C9.17075 7.74976 8.83496 7.41397 8.83496 6.99976C8.83496 6.58554 9.17075 6.24976 9.58496 6.24976C9.99917 6.24976 10.335 6.58554 10.335 6.99976Z' stroke='%23ffffff' stroke-width='1.5'%3E%3C/path%3E%3Cpath d='M15.25 7C15.25 7.41421 14.9142 7.75 14.5 7.75C14.0858 7.75 13.75 7.41421 13.75 7C13.75 6.58579 14.0858 6.25 14.5 6.25C14.9142 6.25 15.25 6.58579 15.25 7Z' stroke='%23ffffff' stroke-width='1.5'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }
    .ql-picker .ql-picker-options [data-value='custom-color']:hover {
        border-color: transparent !important;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    20% {
        transform: translateX(100%);
        opacity: 1;
    }
    100% {
        transform: translateX(0);
    }
}
