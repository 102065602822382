.groupBlock {
  padding: 10px 0;
  margin: 10px 0;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -24px;
    right: -24px;
    height: 1px;
    background-color: #d8d8d8;
  }
  &:last-child {
    padding: 0;
    margin: 0;
    &:after {
      display: none;
    }
  }
}

.isDisabledGroupBlock {
  opacity: 0.5;
  pointer-events: none;
}

.isHasTabGroupBlock {
  padding: 0 0 2px 0;
  margin: 0;
  &:after {
    display: none;
  }
}

.groupTab {
  position: relative;
}

.head {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F2F3F7;
  padding: 8px 0;
  cursor: pointer;
  &:hover {
    .headLabel {
      opacity: .8;
    }
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -24px;
    right: -24px;
    background-color: #F2F3F7;
  }
}

.headContent {
  display: flex;
  align-items: center;
  position: relative;
}

.headIcon {
  margin-right: 10px;
}

.headMark {
  font-size: 10px;
  line-height: 12px;
  padding: 2px 5px;
  border-radius: 20px;
  background-color: #69B1FC;
  color: #fff;
  margin-left: 4px;
}

.headLabel {
  font-size: 14px;
  line-height: 16px;
  color: #3C3C3C;
  transition: opacity .2s ease-in-out;
}

.headArrow {
  position: relative;
}

.isRotatedArrow {
  transform: rotate(180deg);
}

.controls {
  display: none;
  padding: 20px 0;
}

.isVisibleControls {
  display: block;
}