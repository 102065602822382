@import '../../../../../../styles/mixins';

.button {
    padding: 0 8px;
    margin: 0 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
    color: #737373;
    height: 100%;
    white-space: nowrap;
    @include min_rwd(769) {
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            color: #404040;
        }
    }
}

.isSmallButton {
    padding: 0 4px;
    .icon {
        width: 12px;
        height: 12px;
    }
}

.text {
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
}
.textPlaceholder {
    opacity: 0.5;
}
.icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
        max-width: 100%;
        max-height: 100%;
    }
}
.dropDownIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
        max-width: 8px;
        margin-left: 4px;
    }
}

.isActiveButton {
    background-color: rgba(0, 0, 0, 0.1);
    .icon > svg {
        color: #404040;
    }
}

.popover {
    z-index: 1000001;
    background-color: #fff;
    border: 1px solid rgb(229, 229, 229);
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    color: #737373;
    font-size: 10px;
    padding: 4px 8px;
}

.control {
    cursor: pointer;
    width: 8px;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;
    > svg {
        max-width: 100%;
        max-height: 100%;
    }
    &:hover {
        opacity: 0.8;
    }
}
.isDisabledControl {
    pointer-events: none;
    opacity: 0.5;
}
.controlMinus {
    margin-right: 4px;
}
.controlPlus {
    margin: 0 4px;
}
