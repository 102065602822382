.colorPicker {
    padding: 8px;
}

.inputBox {
    width: 200px;
    margin-top: 8px;
}

.input {
    border: 1px solid rgb(229, 229, 229);
    border-radius: 4px;
    appearance: none;
    background-color: #fff;
    outline: none;
    box-sizing: border-box;
    color: #3c3c3c;
    padding: 8px;
    font-size: 14px;
    width: 100%;
    &::placeholder {
        color: #979797;
    }
}

.isErrorInput {
    border-color: #ff5656;
}

.presets {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.preset {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    box-sizing: content-box;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
}

.reset {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 0;
    > svg {
        width: 16px;
        height: 16px;
    }
}

.isDisabledReset {
    opacity: 0.5;
    pointer-events: none;
}
