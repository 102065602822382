@import "../../../../../../styles/mixins";

.playgroundContainer {
  padding: 40px 20px;
  position: relative;
  z-index: 101;
  width: 800px;
  max-width: calc(100% - 40px);
  min-height: auto;
  overflow: hidden;
  @include max_rwd(1455) {
    margin-left: -140px;
  }
  @include max_rwd(768) {
    margin-left: 0;
    max-width: 100%;
  }
}

.overlay {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
}

.page {
  width: 100%;
  height: 100%;
  color: #000;
  border: 1px solid transparent;
  box-sizing: content-box;
  box-shadow: 0 1px 2px rgba(46, 35, 128, 0.15), 0 0 8px -2px rgba(46, 35, 128, 0.15);
  border-radius: 4px;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  position: relative;
  z-index: 50;
}