.devtools {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    transition: transform 0.2s ease-in-out;
    width: 100%;
}

.isOpenedDevtools {
    transform: translate(-50%, 0);
    .tongue > svg {
        transform: rotate(180deg);
    }
}

.tongue {
    position: absolute;
    z-index: 1500;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(46, 35, 128, 0.15), 0 0 8px -2px rgba(46, 35, 128, 0.15);
    border-radius: 10px 10px 0 0;
    height: 30px;
    width: 30px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;
    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        height: 4px;
        width: 100%;
        background-color: #fff;
    }
    &:hover {
        opacity: 0.8;
    }
    > svg {
        max-width: 60%;
        max-height: 60%;
    }
}

.content {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(46, 35, 128, 0.15), 0 0 8px -2px rgba(46, 35, 128, 0.15);
    width: 100%;
}

.contentBox {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
}

.info {
    width: 50%;
}

.infoItem {
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
}

.infoItemTitle {
    font-weight: bold;
}

.infoItemLink {
    color: #2990fb;
    text-decoration: none;
    transition: opacity 0.2s ease-in-out;
    &:hover {
        opacity: 0.8;
    }
    > svg {
        width: 12px;
        height: 12px;
        display: inline-block;
    }
}

.actions {
    position: relative;
}

.actionGroup {
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
}

.actionGroupTitle {
    font-weight: bold;
    margin-bottom: 5px;
}

.actionGroupContent {
    display: flex;
}

.action {
    background-color: #2990fb;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    padding: 10px 14px;
    margin-right: 10px;
    outline: none;
    transition: opacity 0.2s ease-in-out;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    &:hover {
        opacity: 0.8;
    }
    &:last-child {
        margin-right: 0;
    }
}

.isDisabledAction {
    opacity: 0.5;
    pointer-events: none;
}
