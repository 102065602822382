.internal-pages__wrapper {
    width: 100px;
    padding-top: 12px;
}

.internal-pages__auth-wrapper {
    padding-bottom: 12px;
    border-bottom: 1px solid #d8d8d8;
}

.internal-pages__page, .internal-pages__auth-page {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 128px;
    border: 2px solid #d8d8d8;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    cursor: pointer;

    &.select {
        border-color: #2990fb;
        .internal-pages__page-label {
            color: #2990fb;
        }
    }

    &-input {
        position: relative;
        z-index: 3;
        padding: 0;
        .common-text-area__text-area {
            border: none;
            font-size: 12px;
            line-height: 14px;
            padding: 0;

            &:focus:not(:read-only),
            &.is-focused:not(:read-only) {
                border: none;
                border-radius: 0;
                box-shadow: none;
            }
        }
    }

    &-input-wrapper {
        position: fixed;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &-label {
        box-sizing: border-box;
        margin: 12px 7px 0 14px;
        min-height: 32px;
        max-height: 32px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #787878;
        overflow: hidden;
        cursor: default;
    }
    &-img {
        box-sizing: border-box;
        height: 84px;
        margin: 14px 14px 0;
        background-position: top center;
        background-size: contain;
        background-repeat: no-repeat;

        &.is-has-blocks {
            background-image: url('./i/pageContent.svg');
        }
        &.is-has-form {
            background-image: url('./i/authPageContent.svg');
        }
    }
}
