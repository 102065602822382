.periodSelector {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: flex-start;
}

.box {
    display: flex;
}

.dropdownWrapper {
    margin-right: 16px;
}
